import React, { useState, useContext } from "react";
import { Dropdown } from 'primereact/dropdown';
import { AppContext, SocialIcon } from "../../utils"; 

const CreatePost = (props) => {
    const [selectedPage, setSelectedPage] = useState(null);
    const [state] = useContext(AppContext);

    // Organiser les pages par type (Facebook, Instagram, LinkedIn, etc.)
    let groupedPages = [];
    if (state.pages) {
        groupedPages = [
            { 
                label: 'Facebook',
                type: 'facebook',
                items: state.pages
                    .filter(page => page.social_type === 'facebook')
                    .map(page => ({ 
                        label: page.page.name, 
                        value: page.page.id,
                        picture: page.page.picture 
                    }))
            },
            { 
                label: 'Instagram',
                type: 'instagram',
                items: state.pages
                    .filter(page => page.social_type === 'instagram')
                    .map(page => ({ 
                        label: page.page.name, 
                        value: page.page.id,
                        picture: page.page.picture 
                    }))
            },
            { 
                label: 'LinkedIn',
                type: 'linkedin',
                items: state.pages
                    .filter(page => page.social_type === 'linkedin')
                    .map(page => ({ 
                        label: page.page.name, 
                        value: page.page.id,
                        picture: page.page.picture 
                    }))
            },
            // Ajoutez d'autres groupes si nécessaire
        ];
    }

    // Gérer la sélection de la page
    const onPageChange = (e) => {
        setSelectedPage(e.value);
        console.log('Page sélectionnée:', e.value);
    };

    // Fonction de rendu des items avec image
    const itemTemplate = (option) => {
        return (
            <div className="flex items-center">
                {option.picture && (
                    <img 
                        src={option.picture} 
                        alt={option.label} 
                        className="w-6 h-6 rounded-full mr-2" 
                    />
                )}
                <span>{option.label}</span>
            </div>
        );
    };

    // Fonction de rendu des groupes avec une icône
    const optionGroupTemplate = (option) => {
        return (
            <div className="flex items-center">
                <SocialIcon type={option.type} className="w-6 h-6" small />
                <span className="ml-2">{option.label}</span>
            </div>
        );
    };

    // Fonction de rendu pour la valeur sélectionnée
    const selectedItemTemplate = (option) => {
        if (!option) {
            return <span className="placeholder-text">Sélectionnez une page</span>; // Placeholder text
        }

        return (
            <div className="flex items-center">
                {option.picture && (
                    <img 
                        src={option.picture} 
                        alt={option.label} 
                        className="w-6 h-6 rounded-full mr-2" 
                    />
                )}
                <span>{option.label}</span>
            </div>
        );
    };

    return (
        <div className="flex flex-col items-center mt-8">
            <h2>Choisir une page</h2>
            <Dropdown 
                showClear
                value={selectedPage} 
                options={groupedPages.length ? groupedPages : []} // Ensure options are present
                onChange={onPageChange}  
                optionLabel="label"  
                optionGroupLabel="label"  
                optionGroupChildren="items"  
                itemTemplate={itemTemplate}  
                className="w-full md:w-30rem"  
                placeholder="Sélectionnez une page"  
                valueTemplate={selectedItemTemplate} 
                optionGroupTemplate={optionGroupTemplate}  
            />
        </div>
    );
};

export default CreatePost;
