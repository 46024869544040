import axios from "axios";
import Pusher from "pusher-js";
import toast from "react-hot-toast";
import Echo from "laravel-echo";
import { } from "./notification";
import dayjs from "dayjs";
const BASE_URL = process.env.REACT_APP_API_URL + "/api";
let API = {};

API.payment = {
  get: function () {
    return axios.get(BASE_URL + "/package-offers/plans/get");
  },
};

API.user = {
  signin: function ({ email, password }) {
    return axios.post(BASE_URL + "/signin", { email, password });
  },
  signout: function () {
    return axios.get(BASE_URL + "/signout");
  },
  signup: function ({ name, email, pays, password, phone, company }) {
    return axios.post(BASE_URL + "/signup", {
      name,
      email,
      pays,
      password,
      confirmation_password: password,
      phone,
      company,
    });
  },
  me: function () {
    return axios.get(BASE_URL + "/me");
  },
  setupSession: function (data, state, setState) {
    window.localStorage.setItem("storedContext", JSON.stringify(data));
    setState(data);
    API.init(state, setState);
  },
  closeSession: function () {
    localStorage.clear();
    API.user.signout();
    window.location.reload();
  },
  resetPassword: function (email) {
    return axios.post(BASE_URL + "/password/email", { email });
  },
};

API.account = {
  all: function () {
    return axios.get(BASE_URL + "/accounts");
  },
  get: function (account_id) {
    return axios.get(BASE_URL + "/accounts/" + account_id);
  },
  put: function (account_id, { type, nb_employees, nb_accounts }) {
    return axios.put(BASE_URL + "/accounts/" + account_id, {
      type,
      nb_employees,
      nb_accounts,
    });
  },
};

API.categories = {
  all: function () {
    return axios.get(BASE_URL + "/categories");
  },
  bench: function (category_id, from_date, to_date) {
    return axios.post(BASE_URL + "/categories/" + category_id + "/bench", {
      from_date,
      to_date,
    });
  },
  add: function (name, paths) {
    return axios.post(BASE_URL + "/create-categorie", { name, paths });
  },
};

API.socialAccounts = {
  redirection: function (social_type) {
    return BASE_URL + "/social-accounts/auth/" + social_type;
  },
  put: function (social_payload) {
    return axios.post(BASE_URL + "/social-accounts", { social_payload });
  },
  all: function () {
    return axios.get(BASE_URL + "/social-accounts");
  },
  getPages: function (social_account_id) {
    return axios.get(
      BASE_URL + "/social-accounts/" + social_account_id + "/pages"
    );
  },
  storePages: function (social_account_id, enabled, disabled) {
    //enabled: category_id keyed page social id
    //disabled: true keyed page social id
    // key : social id ****** value : categry id
    //window.location.reload(false);
    return axios.post(
      BASE_URL + "/social-accounts/" + social_account_id + "/pages",
      {
        pagesData: { enabled, disabled },
      }
    );
  },
};

API.pages = {
  //get all stored pages
  all: function () {
    return axios.get(BASE_URL + "/pages?include=page");
  },
  put: function (page_id, { goals, category_id, data }) {
    return axios.put(BASE_URL + "/pages/" + page_id, {
      goals,
      category_id,
      data,
    });
  },
  kpis: function (page_id, { from, to }) {
    return axios.post(BASE_URL + "/pages/" + page_id + "/kpis", { from, to });
  },
  getPosts: function (page_id, { user_id, from, to, searchValue, formSearch }) {
    return axios.post(BASE_URL + "/pages/" + page_id + "/posts", { user_id, from, to, searchValue, formSearch });
  },
  exportComments: function (page_id, post_id) {
    return axios.post(
      BASE_URL + "/pages/" + page_id + "/posts/" + post_id + "/comments"
    );
  },
  // begin part 1
  getComments: function (page_id, token) {
    //return axios.get("https://graph.facebook.com/" + page_id + "/comments?access_token=" + token);
    return axios.get("https://graph.facebook.com/105399298059598_399555615648740/comments?access_token=EAADutQr9i3MBANKTu4PZAhsoK3QwBtFa6ymCSwZCiKEfwoeMrlUZBRwZAx3AEZCNKG3qywLIMFh0HoMXt6GPfwJXCFrK0IZC9JHDokbyanFrRqUGMaONrQZBeGr1aJj4iu1fTd3A0VGCZAs9aZCEIUWVCX7rvyVkdIavZAVVHR2AUBWkbZBgtryDySExHRABKUmkEcZD");
  },
  // end part1
  refresh: function (page_id, { post_id, feed }) {
    return axios.post(BASE_URL + "/pages/" + page_id + "/refresh", {
      post_id,
      feed,
    });
  },
  refreshPost: function (page_id, id) {
    return axios.post(BASE_URL + "/pages/" + page_id + "/refresh", { id });
  },
};

API.plannings = {
  all: function (page_id) {
    return axios.get(BASE_URL + "/pages/" + page_id + "/plannings");
  },
  get: function (page_id, planning_id) {
    return axios.get(
      BASE_URL + "/pages/" + page_id + "/plannings/" + planning_id
    );
  },
  delete: function (page_id, planning_id) {
    return axios.delete(
      BASE_URL + "/pages/" + page_id + "/plannings/" + planning_id
    );
  },
  put: function (page_id, planning_id, planning_data) {
    return axios.post(
      BASE_URL +
      "/pages/" +
      page_id +
      "/plannings/" +
      planning_id +
      "?_method=put",
      planning_data
    );
  },
  create: function (page_id, planning_data) {
    return axios.post(
      BASE_URL + "/pages/" + page_id + "/plannings",
      planning_data
    );
  },
};

// begin part 2
API.payload = {
  get: function (txt) {
    //return axios.get("http://host.docker.internal:5000", { params: {payload:txt}});
    return axios.get("http://127.0.0.1:5000", { params: { payload: txt } });
  },
};
// end  part 2

API.notifications = {
  echo: null,
  startEcho: function (user_id) {
    window.Pusher = Pusher;
    API.notifications.echo = new Echo({
      broadcaster: "pusher",
      authEndpoint: BASE_URL + "/broadcasting/auth",
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: true,
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            axios
              .post(BASE_URL + "/broadcasting/auth", {
                socket_id: socketId,
                channel_name: channel.name,
              })
              .then((response) => {
                callback(false, response.data);
              })
              .catch((error) => {
                callback(true, error);
              });
          },
        };
      },
    });
    API.notifications.echo
      .private(`diggow.user.${user_id}`)
      .listenToAll((msg, data) => {
        console.log("Echo Msg", msg);
        const event = new CustomEvent("diggow.msg", { detail: { msg, data } });
        window.dispatchEvent(event);
      });
    /*API.notifications.echo.private('App.Models.User.' + user_id).notification((notification) => {
            console.log(notification.type);
            console.log('Echo Notification',notification);
            const event = new CustomEvent('diggow.notification', { detail: notification });
            document.body.dispatchEvent(event);
        });*/
  },
  stopEcho: function () {
    if (!API.notifications.echo) return;
    API.notifications.echo.disconnect();
    API.notifications.echo = null;
  },
  all: function () {
    return axios.get(BASE_URL + "/notifications");
  },
  markAsRead: function () {
    return axios.post(BASE_URL + "/notifications/markasread");
  },
};

window.API = API;

API.run = async (fn, setStatus) => {
  try {
    await fn();
    setStatus && setStatus(null);
  } catch (e) {
    console.error(e);
    toast("Une erreur est survenue. Merci de réessayer.");
    setStatus && setStatus(null);
  }
};

API.init = async (state, setState, utils) => {
  if (!state?.token) return;
  Promise.all([
    API.user
      .me()
      .then(
        (r) =>
          console.log(r) ||
          API.notifications.startEcho(r.data.data.id) ||
          setState((s) => ({ ...s, userData: r.data.data }))
      ),
    API.pages
      .all()
      .then(
        (r) => console.log(r) || setState((s) => ({ ...s, pages: r.data.data }))
      ),
  ])
    .then((r) =>
      setState((s) => ({
        ...s,
        loaded: true,
        sideBarVisible: true,
        isExpired: false,
        reportingPeriod: [
          dayjs().subtract(1, "month").toDate(),
          dayjs().toDate(),
        ],
      }))
    )
    .catch((r) => {
      if (r?.response?.status == 401) {
        toast("Votre session a expirée.");
        utils.history.push("/login");
        return;
      }
      console.error(r);
      toast("Une erreur est survenue. Merci de réessayer.");
    });
};

// API.comments = {
//   repondre: function (donneesCommentaire) {
//     // Envoie une requête POST vers la route Laravel /api/reponse_comment
//     return axios.post(BASE_URL + "/reponse_comment", { donnees_commentaire: donneesCommentaire });
//   },
// };

API.pages.getMsgComment = function (message) {
  const requestData = {
    message: message
  };

  return axios.post(
    `${BASE_URL}/msg_comment`,
    requestData
  );
};

//Dans votre Api.js, ajoutez une nouvelle méthode pour récupérer les commentaires d'un post
API.pages.saveRepMsg = function (post_id, page_id, selectedCommentId, message, token) {
  const requestData = {
    token: token,
    post_id: post_id,
    page_id: page_id,
    message: message,
    id_comment: selectedCommentId,
  };

  return axios.post(
    `${BASE_URL}/rep_comment`,
    requestData
  );
};

//-- Api Mot Clé
API.pages.sendMotCle = function (page_id, user_id, motcleValues) {
  const requestData = {
    user_id: user_id,
    page_id: page_id,
    motcleValues: motcleValues,
  };

  return axios.post(
    `${BASE_URL}/mot_cle`,
    requestData
  );
};

//-- Api Get Mot Clé
API.pages.getKeys = function (page_id, user_id) {
  const requestData = {
    user_id: user_id,
    page_id: page_id,
  };

  return axios.post(
    `${BASE_URL}/get_mot_cle`,
    requestData
  );
};

//-- Api Rapport Excel
API.pages.rapportExport = function (data_1, data_2) {

  const requestData = {
    name: data_1,
    phone: data_2,
  };

  return axios.post(
    `${BASE_URL}/rapport_excel`,
    requestData
  );
};

//-- Api Get Image Post Library From Public
API.pages.getPageImage = function (pageId, socialId) {

  const requestData = {
    pageId: pageId,
    socialId: socialId,
  };

  return axios.post(
    `${BASE_URL}/get-image-url`,
    requestData
  );
};

//-- Tag
API.pages.tag_save = function (postId, tag) {

  const requestData = {
    postId: postId,
    tag: tag,
  };

  return axios.post(
    `${BASE_URL}/addTag`,
    requestData
  );
};

//-- getCommentsTendance
API.pages.getTendanceApi = function (word, page_id, sentiment, currentPage, isExport, from, to) {

  const requestData = {
    to: to,
    from: from,
    word: word,
    page_id: page_id,
    isExport: isExport,
    sentiment: sentiment,
    currentPage: currentPage,
  };

  return axios.post(
    `${BASE_URL}/get_comments_tendance`,
    requestData
  );
};

//-- getComments
API.pages.getCommentsApi = function (postId, currentPage, search_key, sentiment) {

  const requestData = {
    postId: postId,
    sentiment: sentiment,
    search_key: search_key,
    currentPage: currentPage,
  };

  return axios.post(
    `${BASE_URL}/get_comments`,
    requestData
  );
};

//-- getCommentsExcel
API.pages.getCommentsExcel = function (current_post) {

  const requestData = {
    current_post: current_post,
  };

  return axios.post(
    `${BASE_URL}/export-to-excel`,
    requestData
  );
};

//-- Api Cron Activate
API.pages.cronActivate = function (pageId, managed, dateStart) {

  const requestData = {
    pageId: pageId,
    managed: managed,
    dateStart: dateStart,
  };

  return axios.post(
    `${BASE_URL}/update-data`,
    requestData
  );
};

//-- Api Cron Comments
API.pages.cronComments = function (postId) {

  const requestData = {
    postId: postId,
  };

  return axios.post(
    `${BASE_URL}/update-comment`,
    requestData
  );
};

//-- Api Cron Sector Posts
API.pages.getSectorPostsApi = function (dataBench, from, to) {

  const requestData = {
    dataBench: dataBench,
    from: from,
    to: to,
  };

  return axios.post(
    `${BASE_URL}/getSectorPosts`,
    requestData
  );
};

//-- Api Cron Sector Posts ADS
API.pages.getSectorADSApi = function (brandsADS, brandsSID, currentPage, marque, status, from, to) {

  const requestData = {
    status: status,
    marque: marque,
    brandsADS: brandsADS,
    brandsSID: brandsSID,
    currentPage: currentPage,
    from: from,
    to: to,
  };

  return axios.post(
    `${BASE_URL}/getSectorADS`,
    requestData
  );
};

//-- html2canvas
API.pages.saveMediaRapport = function (formData) {

  const requestData = {
    formData: formData
  };

  return axios.post(
    `${BASE_URL}/saveMediaRapport`,
    requestData
  );
};

API.pages.forgotPassword = function (email)
{
  const requestData = {
    email: email
  };

  return axios.post(
    `${BASE_URL}/forgotPassword`,
    requestData
  );
}

API.pages.resetsPassword = function (data) {
  return axios.post(`${BASE_URL}/resetsPassword`, data);
};

API.pages.fetchResetData = function (data) {
  return axios.post(`${BASE_URL}/fetchResetData`, { data });
};

API.pages.addtwitteraccount = function (name,username,data)
{
  const requestData = {
    name: name,
    username: username,
    data:data
  };

  return axios.post(
    `${BASE_URL}/addtwitteraccount`,
    requestData
  );
}

API.pages.getpagetypeandname = function (user_id) {

  const requestData = {
    user_id: user_id
  };

  return axios.post(
    `${BASE_URL}/getpagetypeandname`,
    requestData
  );
};

API.planificates = {
  getReel(formData) {
    return axios.post(
      `${BASE_URL}/upload-reel`, 
      formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      }
    );
  },
  uploadPhoto(formData) {
    return axios.post(
      `${BASE_URL}/upload-photo`, 
      formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      }
    );
  },
  uploadVideo(formData) {
    return axios.post(
      `${BASE_URL}/upload-video`, 
      formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      }
    );
  },
  uploadStoriePhoto(formData) {
    return axios.post(
      `${BASE_URL}/upload-storie-photo`, 
      formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      }
    );
  },
  uploadStorieVideo(formData) {
    return axios.post(
      `${BASE_URL}/upload-storie-video`, 
      formData, 
      {
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      }
    );
  },
  getFeed(formData) {
    return axios.post(
      `${BASE_URL}/publish-feed`, 
      formData
    );
  },
  uploadYtb(formData) {
    return axios.post(
      `${BASE_URL}/publish-ytb`, 
      formData
    );
  }
};

export default API;

