import React, { useState } from 'react';
import { API } from "../../utils";

function UploadReel() {
    const [url, setUrl] = useState('');
    const [video, setVideo] = useState(null);
    const [description, setDescription] = useState('');

    const handleFileChange = (e) => {
        setUrl('');
        setVideo(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('url', url);
        formData.append('video', video);
        formData.append('description', description);

        try {
            const response = await API.planificates.getReel(formData);
            console.log('Success:', response);
        } catch (error) {
            console.error('Error uploading video:', error);
        }
    };

    return (
        <div>
            <h1>Upload Reel</h1>
            <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileChange} />
                <input
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="Url"
                />
                <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                />
                <button type="submit">Upload</button>
            </form>
        </div>
    );
}

export default UploadReel;
