import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import '@fortawesome/fontawesome-free/css/all.css';
import { useNavigate } from 'react-router-dom';

const Planifications = () => {
  const [events, setEvents] = useState([]); // State to hold events
  const navigate = useNavigate(); // Hook to navigate

  // Function to handle date click
  const handleDateClick = (info) => {
    const clickedDate = info.dateStr; // Get the clicked date
    const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

    if (clickedDate === today) {
      // Directly redirect without showing popup if the clicked date is today
      navigate('/createpost');
    } else if (clickedDate > today) {
      // Redirect with the selected future date
      navigate('/createpost', { state: { date: clickedDate } });
    } else {
      console.log('Past dates cannot be clicked'); // Optional: Log or show a message
    }
  };

  // Handle Add New Post button click
  const handleAddNewPost = () => {
    navigate('/createpost'); // Redirect without a date
  };

  const today = new Date().toISOString().split('T')[0]; // Get today's date

  return (
    <div>
      {/* FullCalendar component */}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'today prev,next',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,myCustomButton'
        }}
        customButtons={{
          myCustomButton: {
            text: 'Add New Post',
            click: handleAddNewPost // Handle add new post button click
          }
        }}
        events={events} // Set events from state
        dayMaxEvents={true}
        dateClick={(info) => {
          // Allow clicking on today's date or future dates
          if (info.date >= new Date(new Date().setHours(0, 0, 0, 0))) {
            handleDateClick(info); // Proceed only if date is today or in the future
          }
        }}
        validRange={{
          start: today // Disable navigating to dates before today
        }}
      />
    </div>
  );
};

export default Planifications;
